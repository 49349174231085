'use strict';
import Swiper, { Navigation, Autoplay, } from 'swiper';

(function() {

    /** categories */
    const categoriesCarousel = new Swiper( '.categories-container .categories', {
        loop: true,
        slidesPerView: 4,
        centeredSlides: true,
        modules: [ Navigation, Autoplay ],
        breakpoints: {
            500: {
                slidesPerView: 5
            },
            767: {
                slidesPerView: 7
            }, 
            1100: {
                slidesPerView: 9
            }
        },
        autoplay: true,
        navigation: {
            nextEl: '.stories-navigation.swiper-button-next',
            prevEl: '.stories-navigation.swiper-button-prev',
        },
    })


    /** stories */
    const storiesCarousel = new Swiper( '.stories-container .stories', {
        loop: true,
        slidesPerView: 1.6,
        speed: 500,
        modules: [ Autoplay, Navigation ],
        autoplay: 5000,
        breakpoints: {
            500: {
                slidesPerView: 1.6
            },
            767: {
                slidesPerView: 4
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    })

    /** team */
    const teamCarousel = new Swiper( '.team.swiper', {
        slidesPerView: 1.6,
        loop: true,
        modules: [ Autoplay, Navigation ],
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.team-navigation.nav-next',
            prevEl: '.team-navigation.nav-prev'
        },
        breakpoints: {
            500: {
                slidesPerView: 1.6
            },
            767: {
                slidesPerView: 4
            }
        },
    });

    /** services */
    const servicesCarousel = new Swiper( '.services-container .services', {
        slidesPerView: 1.6,
        modules: [ Autoplay, Navigation ],
        autoplay: true,
        breakpoints: {
            500: {
                slidesPerView: 1.6
            },
            767: {
                slidesPerView: 4
            }
        },
        navigation: {
            nextEl: '.services-navigation.nav-next',
            prevEl: '.services-navigation.nav-prev'
        }
    })

    /** services */
    const servicesCarouselBlog = new Swiper( '.services-container .blog', {
        slidesPerView: 2,
        modules: [ Autoplay, Navigation ],
        autoplay: true,
        breakpoints: {
            500: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 2
            }
        },
        navigation: {
            nextEl: '.services-navigation.nav-next',
            prevEl: '.services-navigation.nav-prev'
        }
    })

})();